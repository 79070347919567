import React, { memo } from 'react';
import { fill, flex, pureGrid } from '~/modules/AppLayout/FlexGridCss';
import { css } from '@emotion/react';
import { useThemeStore } from '~/components/theme/useThemeStore';
import styled from '@emotion/styled';
const titleCss = css `
  ${flex.h.allCenter}
  ${pureGrid};
  width: 100%;
  grid-template-columns: 25% 18.75% 18.75% 18.75% 18.75%;
  height: 30px;
  background-color: #333333;
  font-size: 14px;
`;
const titleSimpleCss = css `
  ${titleCss};
  grid-template-columns: 25% 25% 25% 25%;
`;
const Standard = memo(function Title(props) {
    const theme = useThemeStore(s => s.theme);
    return (<classes.Root css={baseStyled}>
      <classes.containerStandard className={theme}>
        <div css={css `
            ${flex.h.allCenter}
            grid-column: 1 / 2;
          `}>
          名稱
        </div>
        {props.countArray.map(datum => {
            return (<div css={css `
                ${flex.h.allCenter}
              `} key={datum}>
              {datum}日
            </div>);
        })}
      </classes.containerStandard>
    </classes.Root>);
});
const Simple = memo(function Simple(props) {
    const theme = useThemeStore(s => s.theme);
    return (<classes.Root css={baseStyled}>
      <classes.containerSimple className={theme}>
        {props.countArray.map(datum => {
            return (<div css={flex.h.allCenter} key={datum}>
              {datum}日
            </div>);
        })}
      </classes.containerSimple>
    </classes.Root>);
});
const classes = {
    Root: styled.div `
    ${fill};
    height: 30px;
  `,
    containerStandard: styled.div `
    ${flex.h.allCenter}
    ${pureGrid};
    width: 100%;
    grid-template-columns: 25% 18.75% 18.75% 18.75% 18.75%;
    height: 30px;
    font-size: 14px;
  `,
    containerSimple: styled.div `
    ${flex.h.allCenter}
    ${pureGrid};
    width: 100%;
    grid-template-columns: 25% 25% 25% 25%;
    height: 30px;
    font-size: 14px;
  `,
};
const baseStyled = css `
  ${classes.Root} {
  }
  ${classes.containerStandard} {
    &.dark {
      background-color: #333333;
    }
    &.light {
      background-color: #eeeeee;
    }
  }
  ${classes.containerSimple} {
    &.dark {
      background-color: #333333;
    }
    &.light {
      background-color: #eeeeee;
    }
  }
`;
export default {
    Standard: Standard,
    Simple: Simple,
    classes,
};
