import React, { memo, useMemo } from 'react';
import { css } from '@emotion/react';
import dayjs from 'dayjs';
import { flex } from '~/modules/AppLayout/FlexGridCss';
import { representationAsNTD } from '~/utils/representationAsNTD';
import { ResponsiveContainer, CartesianGrid, Tooltip, XAxis, YAxis, ReferenceLine, AreaChart, Area, } from 'recharts-new';
import { useTradingSummaryHashState } from '~/modules/tradeSheet/tradingAnalyzer/useTradingSummaryHashState';
import { toReadableUnitChinese } from '~/utils/representChineseNumberFormat';
const EquityCurveChart = memo(function EquityCurveChart() {
    const { state } = useTradingSummaryHashState.useContainer();
    const { dailyPnl } = state;
    const oldestDatetime = dailyPnl && dailyPnl.length ? dailyPnl[0].date : undefined;
    const data = useMemo(() => dailyPnl?.map(acc => ({
        accProfit: acc.accPnl,
        time: dayjs(acc.date).unix(),
        adjustTime: (dayjs(acc.date).unix() - dayjs(oldestDatetime).unix()) / 1000,
    })) ?? [], [dailyPnl, oldestDatetime]);
    if (!data.length)
        return (<div css={css `
          height: 100%;
          ${flex.h.allCenter};
        `}>
        無此區間資料
      </div>);
    const CustomizedXAxisLabel = memo(function CustomizedXAxisLabel({ tickProps }) {
        const { x, y, payload } = tickProps;
        const origin = data.find(datum => datum.adjustTime === payload.value);
        return (<svg>
        <text textAnchor='middle' letterSpacing={-1} x={x} y={y} fontSize={13}>
          {origin && dayjs(origin.time * 1000).format('MM/DD')}
        </text>
      </svg>);
    });
    const gradientOffset = () => {
        const dataMax = Math.max(...data.map(i => i.accProfit));
        const dataMin = Math.min(...data.map(i => i.accProfit));
        if (dataMax <= 0)
            return 0;
        if (dataMin >= 0)
            return 1;
        return dataMax / (dataMax - dataMin);
    };
    const off = gradientOffset();
    return (<ResponsiveContainer width='100%' height='100%'>
      <AreaChart data={data}>
        <CartesianGrid stroke='#eee' strokeDasharray='5 5'/>
        <XAxis dataKey='adjustTime' type='number' ticks={[...data.map(d => d.adjustTime)]} tick={p => <CustomizedXAxisLabel tickProps={p}/>} tickMargin={20}/>
        <YAxis dataKey='accProfit' tickLine={false} tick={p => <CustomizedYLabel tickProps={p}/>}/>
        <ReferenceLine y={0} stroke='#aaaaaa' isFront={true}/>
        <Tooltip content={<CustomizedTooltip />}/>

        <defs>
          <linearGradient id='splitColor' x1='0' y1='0' x2='0' y2='1'>
            <stop offset={off} stopColor='#cc2222' stopOpacity={1}/>
            <stop offset={off} stopColor='#11aa11' stopOpacity={1}/>
          </linearGradient>
        </defs>
        <Area type='linear' dataKey='accProfit' stroke='url(#splitColor)' fillOpacity={0.3} fill='url(#splitColor)'/>
      </AreaChart>
    </ResponsiveContainer>);
});
const CustomizedTooltip = ({ payload }) => {
    if (!payload || (payload && payload.length < 1))
        return null;
    const datum = payload[0].payload;
    return (<div css={css `
        background: #ffffff;
        padding: 0 8px;
        border-radius: 8px;
        border: #a0a0a0 1px solid;
      `}>
      <p>{dayjs(datum.time * 1000).format('MM/DD')}</p>

      <p>累計： {representationAsNTD(datum.accProfit)}</p>
    </div>);
};
const CustomizedYLabel = memo(function CustomizedYLabel({ tickProps }) {
    const { x, y, payload } = tickProps;
    return (<svg>
      <text fontSize={14} x={x - 30} y={y + 3}>
        {toReadableUnitChinese(payload.value)}
      </text>
    </svg>);
});
export default EquityCurveChart;
