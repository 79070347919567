import styled from '@emotion/styled';
import { memo } from 'react';
import { fill_horizontal_all_center } from '~/modules/AppLayout/FlexGridCss';
export const David0705_Footer = memo(function David0705_Footer(props) {
    return (<styleds.container>
      期貨交易有一定風險 交易人應先評估本身資金及能擔負之風險
      {/* <David0705_BottomMenu /> */}
    </styleds.container>);
});
const styleds = {
    container: styled.div `
    ${fill_horizontal_all_center};
    background-image: linear-gradient(to bottom, #131313e8, #222222cc),
      url('david0705/background.png');
    background-size: cover;
    font-size: 14px;
  `,
};
