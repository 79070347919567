import { useEffect } from 'react';
import { useMount, useUpdateEffect } from 'react-use';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { huanan_store } from '~/pages/david0705/trading_analyzer/huanan_store';
import { __IS_CLIENT__ } from '~/utils/__IS_CLIENT__';
export const usePageThemeMode = (themeMode) => {
    const theme = useThemeStore(state => state.theme);
    if (__IS_CLIENT__) {
        localStorage.removeItem('tradingview.current_theme.name');
        localStorage.removeItem('tradingview.chartproperties');
        localStorage.removeItem('tradingview.chartproperties.mainSeriesProperties');
        huanan_store.charting.setThemeMode(themeMode);
    }
    useMount(() => {
        useThemeStore.setState({ theme: themeMode });
    });
    useUpdateEffect(() => {
        if (themeMode !== theme) {
            useThemeStore.setState({ theme: themeMode });
        }
    }, [theme]);
    useEffect(() => {
        try {
            huanan_store.charting.updateFromThemeMode();
        }
        catch (error) {
            //
        }
    }, [themeMode]);
};
