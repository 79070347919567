import { store } from '~/pages/heineken_template/_private/store';
import { css } from '@emotion/react';
import { createIPadCss } from '~/css/createIPadCss';
import { createMobileCss2 } from '~/css/createMobileCss';
import { useThemeStore } from '~/components/theme/useThemeStore';
import { Charting } from '~/pages/heineken_template/_private/Charting';
import { David0705_SidePane2 } from './david0705_SidePane2';
import { david0705_initStrategies } from './david0705_initStrategies';
import { David0705_SidePane1 } from './david0705_SidePane1';
import { David0705_Footer } from './david0705_Footer';
import { TradingSummary } from './trading_analyzer/TradingAnalyzer';
import { ChipsOverView } from './chips_overview/ChipsOverview';
import { meCheckHandlerCreateByProductNameWithExpiredAt } from '~/modules/SDK/me/meCheckHandlerCreateByProductName';
import { Preset_LoginPageOfCarousel } from '../heineken_template/_preset/preset_LoginPageOfCarousel';
import { David0705_BottomMenu, pageStore } from './david0705_BottomMeun';
import { TradeInfo } from './trading_info/TradeInfo';
import { David0705_Topbar } from './david0705_Topbar';
export const david0705_init = {
    global(templateProps) {
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.layout.Charting = undefined;
        templateProps.layout.Drawer1 = David0705_SidePane1;
        templateProps.layout.Row1 = David0705_Topbar;
        templateProps.layout.Row2 = David0705_Footer;
    },
    indexPage(templateProps) {
        david0705_init.global(templateProps);
        david0705_initStrategies();
        pageStore.state = '高勝';
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
        store.charting.widgetOptions = {
            ...store.charting.widgetOptions,
            symbol: 'TX-1',
            interval: '5',
            enableVolumeIndicator: false,
            disabledHeaderWidget: false,
            disabledLeftToolbar: false,
            disabledPaneMenu: true,
            disabledTimeframesToolbar: true,
            disabledHeaderChartType: true,
            disabledHeaderSaveload: false,
            disabledHeaderCompare: false,
            overrides: {
                ...store.charting.darkOverrides,
                'paneProperties.legendProperties.showLegend': false,
                'paneProperties.topMargin': 5,
                'paneProperties.bottomMargin': 5,
                'scalesProperties.fontSize': 14,
                'scalesProperties.textColor': '#ffffff',
                'paneProperties.backgroundType': 'gradient',
                'paneProperties.backgroundGradientStartColor': '#141517',
                'paneProperties.backgroundGradientEndColor': '#171718',
                'paneProperties.vertGridProperties.color': '#333333',
                'paneProperties.horzGridProperties.color': '#333333',
            },
        };
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 48px calc(100vh - 96px) 164px;
      grid-template-columns: 336px 336px 1fr;
      place-content: flex-start;

      background-image: linear-gradient(to bottom, #222222cc, #222222cc),
        url('david0705/background.png');
      background-size: cover;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Drawer1 Drawer2 Chart'
        'Row2 Row2 Row2';

      ${createIPadCss(css `
        grid-template-rows: 48px calc(100vh - 96px) 48px 164px;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px calc(100vh - 96px) 48px 164px;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Chart Chart Chart'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}
    `;
        // Init components
        templateProps.layout.Charting = Charting;
        templateProps.layout.Row1 = David0705_Topbar;
        templateProps.layout.Row2 = David0705_Footer;
        templateProps.layout.Row3 = David0705_BottomMenu;
        templateProps.layout.Drawer1 = David0705_SidePane1;
        templateProps.layout.Drawer2 = David0705_SidePane2;
        //templateProps.layout.Col1 = David0705_SidePaneMenu
        templateProps.layout.login = (<Preset_LoginPageOfCarousel resources={[{ image: [true, '/david0705/loginView.png'] }]} css={css `
          background-image: linear-gradient(to bottom, #222222cc, #222222cc),
            url('david0705/background.png');
          background-size: cover;

          & > div {
            border-radius: 8px;
            box-shadow: 0 0 4px 2px #333333;
          }

          .css-u2s55s-MediaBanner {
            box-shadow: 0 0 6px 4px #222222;
            border: 2px solid #617ad1;
          }
          .css-bxjr3y-MuiButtonBase-root-MuiButton-root-basisCSS-buttonCss-css {
            background-image: linear-gradient(270deg, #fa8bb6 0%, #580e9c 100%);
            &:hover {
              background-image: linear-gradient(270deg, #fa9bb6 0%, #582e9c 100%);
            }
          }
        `}/>);
    },
    ['trading_analyzer/index.page'](templateProps) {
        david0705_init.global(templateProps);
        pageStore.state = '健檢';
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 48px calc(100vh - 96px) 164px;
      grid-template-columns: 336px 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Drawer1 Col1 Col1'
        'Row2 Row2 Row2';

      ${createIPadCss(css `
        grid-template-rows: 48px calc(100vh - 96px) 48px 164px;
        grid-template-columns: 1fr;
        place-content: flex-start;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px calc(100vh - 96px) 48px 164px;
        grid-template-columns: 1fr 1fr 1fr;
        place-content: flex-start;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}
    `;
        // Init components
        templateProps.layout.Row1 = David0705_Topbar;
        templateProps.layout.Col1 = TradingSummary;
        templateProps.layout.Row2 = David0705_Footer;
        templateProps.layout.Row3 = David0705_BottomMenu;
        templateProps.layout.Drawer1 = David0705_SidePane1;
        templateProps.layout.login = <Preset_LoginPageOfCarousel resources={[{ image: [true, ''] }]}/>;
    },
    ['trading_info/index.page'](templateProps) {
        david0705_init.global(templateProps);
        pageStore.state = '圖表';
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 48px calc(100vh - 96px) 164px;
      grid-template-columns: 336px 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Drawer1 Col1 Col1'
        'Row2 Row2 Row2';

      ${createIPadCss(css `
        grid-template-rows: 48px calc(100vh - 96px) 48px 164px;
        grid-template-columns: 1fr;
        place-content: flex-start;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px calc(100vh - 96px) 48px 164px;
        grid-template-columns: 1fr 1fr 1fr;
        place-content: flex-start;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}
    `;
        // Init components
        templateProps.layout.Row1 = David0705_Topbar;
        templateProps.layout.Col1 = TradeInfo;
        templateProps.layout.Row2 = David0705_Footer;
        templateProps.layout.Row3 = David0705_BottomMenu;
        templateProps.layout.Drawer1 = David0705_SidePane1;
        templateProps.layout.login = <Preset_LoginPageOfCarousel resources={[{ image: [true, ''] }]}/>;
    },
    ['chips_overview/index.page'](templateProps) {
        david0705_init.global(templateProps);
        pageStore.state = '籌碼';
        useThemeStore.setState({ theme: 'dark' });
        store.charting.setThemeMode('dark');
        templateProps.permissions.pageview = meCheckHandlerCreateByProductNameWithExpiredAt('web');
        templateProps.layout.cssset = css `
      grid-template-rows: 48px 48px calc(100vh - 96px) 164px;
      grid-template-columns: 336px 1fr;
      place-content: flex-start;

      grid-template-areas:
        'Row1 Row1 Row1'
        'Row3 Row3 Row3'
        'Drawer1 Col1 Col1'
        'Row2 Row2 Row2';

      ${createIPadCss(css `
        grid-template-rows: 48px calc(100vh - 96px) 48px 164px;
        grid-template-columns: 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}

      ${createMobileCss2(css `
        grid-template-rows: 48px calc(100vh - 160px) 48px 164px;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-areas:
          'Row1 Row1 Row1'
          'Col1 Col1 Col1'
          'Row3 Row3 Row3'
          'Row2 Row2 Row2';
      `)}
    `;
        // Init components
        templateProps.layout.Row1 = David0705_Topbar;
        templateProps.layout.Col1 = ChipsOverView;
        templateProps.layout.Row2 = David0705_Footer;
        templateProps.layout.Row3 = David0705_BottomMenu;
        templateProps.layout.Drawer1 = David0705_SidePane1;
        templateProps.layout.login = <Preset_LoginPageOfCarousel resources={[{ image: [true, ''] }]}/>;
    },
};
