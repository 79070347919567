import useSWR from 'swr-0-5-6';
import { apirc } from '~/configs/apirc';
const WIP_ROOT_URL = apirc.unclassified.tradingSummaryUploadVersion + '/api/v2/tradingSummary';
const DOCKER_URL = 'http://localhost:5050/api/v2/tradingSummary';
const defaultConfig = { shouldRetryOnError: true, errorRetryCount: 0, revalidateOnFocus: false };
export const useAccountPerformance = (hashString, since) => {
    const res = useSWR(hashString ? `${WIP_ROOT_URL}/performance?hashString=${hashString}&sinceWhen=${since}` : null, fetcherWithToken, defaultConfig);
    return res.data;
};
const fetcherWithToken = async (url, token) => {
    return fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`,
        },
    }).then(res => {
        if (res.status !== 200) {
            return null;
        }
        return res.json();
    });
};
