import dayAPI from '~/utils/dayAPI';
export const disableWeekends = (date) => {
    return date.weekday() === 0 || date.weekday() === 6;
};
export const fetchDate = (inputdate) => {
    const todaydate = dayAPI();
    /** 周一開盤以前，回推至上周五 */
    if (todaydate.weekday() === 1 && todaydate.isBefore(todaydate.hour(8).minute(45)))
        return dayAPI(inputdate).add(-3, 'day');
    /** 周六 -> 回推至周五 */
    if (inputdate.weekday() === 6)
        return dayAPI(inputdate).add(-1, 'day');
    /** 周日 -> 回推至周五 */
    if (inputdate.weekday() === 0)
        return dayAPI(inputdate).add(-2, 'day');
    /** 尚未開盤，回推至昨日的日期 */
    if (todaydate.isBefore(todaydate.hour(8).minute(45)) &&
        dayAPI(todaydate).format('MM-DD') === dayAPI(inputdate).format('MM-DD'))
        return dayAPI(inputdate).add(-1, 'day');
    /** 盤中 */
    return dayAPI(inputdate);
};
