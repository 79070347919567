import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo, useEffect } from 'react';
import useSWR from 'swr-0-5-6';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_cross_center, } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { staticStore } from '~/pages/heineken_template/_private/staticStore';
export const emptyDataAvergeValue = [
    { interval: 'minutes0', midValue: 0, horseValue: 0 },
];
export const emptyTradeInfo = [
    { position: 0, signal: 0, entryTime: '00:00', entryPrice: 0, updateTime: '00:00' },
];
//待抽
const errorEmptyArrayFetcher = async (url) => {
    const res = await fetch(url);
    return !res.ok ? [] : res.json();
};
//待抽
export const useHuananAvergeValue = (date) => {
    const url = `https://trading-event-handler-ytvffgctuq-de.a.run.app/get_tradeinfo?type=huanan_average_value&date=` +
        date;
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
//待抽
export const useHuananTradeInfo = (date) => {
    const url = `https://trading-event-handler-ytvffgctuq-de.a.run.app/get_tradeinfo?type=huanan_tradeinfo&date=` +
        date;
    const res = useSWR(url, errorEmptyArrayFetcher, {
        shouldRetryOnError: false,
    });
    return {
        data: res.data,
    };
};
const CardTitle = memo(function CardTitle() {
    return (<styleds.row css={css `
        height: 36px;
        background-color: #343434;
      `}>
      <p>週期配對</p>
      <p>中線</p>
      <p>馬線</p>
      <p>差值</p>
      <p>狀態</p>
    </styleds.row>);
});
const CardItem = memo(function CardItem(props) {
    useEffect(() => {
        signalrStore2.addQuote(['TX-1']);
        return () => {
            signalrStore2.removeQuote(['TX-1']);
        };
    }, [JSON.stringify(['TX-1'])]);
    const quote = useSnapshot(signalrStore2.values.quote)['TX-1'];
    const interval1 = props.data1?.interval?.replace('minutes', '') ?? '-';
    const interval2 = props.data2?.interval?.replace('minutes', '') ?? '-';
    //21ma中 大週期
    const mid_1 = props.data1?.midValue;
    //120ma馬 小週期
    const horse_2 = props.data2?.horseValue;
    // //21ma 小週期
    // const mid_2 = props.data2.midValue
    // //120ma 大週期
    // const horse_1 = props.data1.horseValue
    const close = quote?.close ?? 0;
    const high = quote?.high ?? 0;
    const low = quote?.low ?? 0;
    const maDiff = mid_1 - horse_2;
    const closeMa21Diff = close - mid_1;
    const maLongCondition = maDiff >= -6 && maDiff <= 6;
    const maShortCondition = maDiff >= -6 && maDiff <= 6;
    // 21ma - 120ma 相差6-9點內
    // close - 21ma 相差20點內
    // 收盤 > 21ma
    const long = close > mid_1 && closeMa21Diff <= 20 && maLongCondition;
    const short = close < mid_1 && Math.abs(closeMa21Diff) <= 20 && maShortCondition;
    const state = long ? '支撐' : short ? '壓力' : '無狀態';
    const valueFill = () => {
        if (long) {
            return '#ff3333';
        }
        else if (short) {
            return '#33bb33';
        }
        else
            return '#eeeeee';
    };
    return (<styleds.row css={css `
        height: 32px;
        background-color: #252525;
      `}>
      <p>
        <styleds.intervalItem>
          {interval1} / {interval2}
        </styleds.intervalItem>
      </p>
      <p>{mid_1}</p>
      <p>{horse_2}</p>
      <p>{closeMa21Diff}</p>
      <p css={css `
          color: ${valueFill()};
        `}>
        {state}
      </p>
    </styleds.row>);
});
export const MaRatio = memo(function MaRatio() {
    const intradeDay = useSnapshot(staticStore.tradedDate.intraday);
    const avergeValueData = useHuananAvergeValue(intradeDay.format('YYYY-MM-DD')).data;
    const data = Array.isArray(avergeValueData) ? avergeValueData : emptyDataAvergeValue;
    const m60 = data?.filter(s => s.interval === 'minutes60')[0] ?? emptyDataAvergeValue[0];
    const m30 = data?.filter(s => s.interval === 'minutes30')[0] ?? emptyDataAvergeValue[0];
    const m15 = data?.filter(s => s.interval === 'minutes15')[0] ?? emptyDataAvergeValue[0];
    const m5 = data?.filter(s => s.interval === 'minutes5')[0] ?? emptyDataAvergeValue[0];
    const m3 = data?.filter(s => s.interval === 'minutes5')[0] ?? emptyDataAvergeValue[0];
    return (<div css={css `
        ${fill_vertical_cross_center};
        height: 148px;
        gap: 4px;
        padding: 2px;
      `}>
      <CardTitle />
      <div css={css `
          ${fill_vertical_cross_center};
          height: calc(100% - 36px);
          gap: 4px;
        `}>
        <CardItem data1={m60} data2={m15}/>
        <CardItem data1={m30} data2={m5}/>
        <CardItem data1={m5} data2={m3}/>
      </div>
    </div>);
});
const styleds = {
    row: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 24% 20% 20% 16% 20%;
    width: 100%;
    border-radius: 4px;
    padding: 0px 4px;
    font-size: 14px;
    & > p {
      display: flex;
      margin: 0px;
      justify-content: center;
      align-items: center;
    }
  `,
    intervalItem: styled.div `
    ${fill_horizontal_all_center};
    width: 80%;
    height: 68%;
    background-color: #343434;
    border-radius: 5px;
  `,
};
