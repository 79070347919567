import { globalRed, bluePurple300, globalBlue, globalGrey, globalYellow, globalGreen, purple300, globalOrange, } from '~/modules/AppLayout/Colors';
export const PROPORTION_COLORS = [
    globalRed.r400,
    bluePurple300,
    globalBlue.b300,
    globalGrey.g700,
    globalYellow.y600,
    globalGreen.g600,
    purple300,
    globalOrange.o300,
    globalRed.r500,
];
