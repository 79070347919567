import React, { memo } from 'react';
import { Paper, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { Time, useTimeRangeStore } from '~/modules/tradeSheet/shared/useTimeRangeStore';
const TimeRangeTabs = memo(function TimeRangeTabs() {
    const since = useTimeRangeStore(s => s.since);
    const change = useTimeRangeStore(s => s.changeTimeRange);
    const handleTimeRange = (_, range) => {
        if (range)
            change(range);
    };
    return (<Paper>
      <ToggleButtonGroup value={since} exclusive onChange={handleTimeRange}>
        <ToggleButton value={'N1M'}>{Time['N1M']}</ToggleButton>
        <ToggleButton value={'N3M'}>{Time['N3M']}</ToggleButton>
        <ToggleButton value={'NHY'}>{Time['NHY']}</ToggleButton>
        <ToggleButton value={'N1Y'}>{Time['N1Y']}</ToggleButton>
        <ToggleButton value={'NTY'}>{Time['NTY']}</ToggleButton>
      </ToggleButtonGroup>
    </Paper>);
});
export default TimeRangeTabs;
