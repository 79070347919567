import { store } from '../heineken_template/_private/store';
import { huanan_day_range } from '~/trades/indicators/defaults/huanan_day_range';
import { huanan_day_range_5 } from '~/trades/indicators/defaults/huanan_day_range_5';
import { huanan_ColoredMovAvg } from '~/trades/indicators/huanan/huanan_ColoredMovAvg';
import { huanan_DualBBand } from '~/trades/indicators/huanan/huanan_DualBBand';
import { eurex_main_trend } from '~/trades/indicators/eurex/eurex_main_trend';
import { huanan_DailyRange } from '~/trades/indicators/huanan/huanan_DailyRange';
const DEFAULT_SYMBOL = 'TX-1';
const txIndicatorGroup = [
    huanan_day_range,
    huanan_day_range_5,
    huanan_ColoredMovAvg,
    huanan_DualBBand,
    eurex_main_trend,
    huanan_DailyRange,
];
export const david0705_strategyGroup = {
    main: [
        {
            displayName: 'Main strategy group',
            indicators: txIndicatorGroup,
            symbol: DEFAULT_SYMBOL,
            interval: '5',
            panesRatio: 80,
        },
    ],
};
export const david0705_initStrategies = () => {
    store.charting.indicatorsPreloaded = [...store.charting.indicatorsPreloaded, ...txIndicatorGroup];
    store.charting.initStrategy({
        configs: [...david0705_strategyGroup.main],
    });
    store.charting.changeSymbol(DEFAULT_SYMBOL);
};
