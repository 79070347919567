import React, { memo, useState, useCallback } from 'react';
import { css } from '@emotion/react';
import { globalRed, globalGreen, globalYellow, bluePurpleA400, globalOrange, globalGrey, globalBlue, BLACK, purple300, } from '~/modules/AppLayout/Colors';
import { PieChart, ResponsiveContainer, Sector, Pie, Cell, Legend, Tooltip } from 'recharts-new';
import { Paper } from '@mui/material';
import { fontWeight600 } from '~/css/font';
const RADIAN = Math.PI / 180;
const CustomizedPieLabel = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, index } = props;
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    return (<text x={x} y={y} fill='white' textAnchor={'middle'} dominantBaseline='central'>
      {percent < 0.1 ? '' : (percent * 100).toFixed(0) + '%'}
    </text>);
};
const ActiveShape = (props) => {
    const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props;
    return (<g>
      <Sector cx={cx} cy={cy} innerRadius={innerRadius} outerRadius={outerRadius} startAngle={startAngle} endAngle={endAngle} fill={fill}/>
      <Sector cx={cx} cy={cy} startAngle={startAngle} endAngle={endAngle} innerRadius={outerRadius + 4} outerRadius={outerRadius + 7} fill={fill}/>
    </g>);
};
/** useTradingSummaryResource.tsx 有高達9種類別 */
const colors = [
    bluePurpleA400,
    globalRed.r700,
    globalYellow.y800,
    globalGreen.gA700,
    globalOrange.o600,
    globalBlue.b400,
    globalGrey.g400,
    purple300,
    BLACK,
];
const CategoryPieChart = memo(function CategoryPieChart({ data, width, height }) {
    const [activeIndex, setActiveIndex] = useState(NaN);
    const onMouseOver = useCallback((_data, index) => {
        setActiveIndex(index);
    }, []);
    const onMouseLeave = useCallback((_data, _) => {
        setActiveIndex(NaN);
    }, []);
    return (<ResponsiveContainer width='100%' height='100%'>
        <PieChart width={width || 400} height={height || 400}>
          <Legend iconSize={16} iconType={'circle'}/>
          <Tooltip content={<CustomizedTooltip />}/>
          <Pie activeIndex={activeIndex} activeShape={ActiveShape} onMouseOver={onMouseOver} onMouseLeave={onMouseLeave} data={data} cx='50%' cy='50%' labelLine={false} label={CustomizedPieLabel} outerRadius={(width || 400) / 3} dataKey='value' isAnimationActive={false}>
            {data.map((entry, index) => (<Cell key={`cell-${index}`} fill={colors[index % colors.length]}/>))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>);
});
const CustomizedTooltip = ({ payload }) => {
    if (!payload || (payload && payload.length < 1))
        return null;
    const [name, value] = [payload[0].name, payload[0].value];
    return (<Paper css={css `
        padding: 8px;
        border-radius: 4px;
        font-size: 16px;
        ${fontWeight600};
        border: ${globalGrey.g50} 1px solid;
        & > p {
          margin: 4px 0;
        }
      `}>
      <div>
        {name}: {(value * 100).toFixed(2)}%
      </div>
    </Paper>);
};
export default CategoryPieChart;
