import { createStore } from '~/store/createStore';
// N1M, N3M, NHY, N1Y, NTY
export var Time;
(function (Time) {
    Time["N1M"] = "\u8FD1\u4E00\u6708";
    Time["N3M"] = "\u8FD1\u4E09\u6708";
    Time["NHY"] = "\u8FD1\u534A\u5E74";
    Time["N1Y"] = "\u8FD1\u4E00\u5E74";
    Time["NTY"] = "\u4ECA\u5E74";
})(Time || (Time = {}));
export const useTimeRangeStore = createStore((set, get) => {
    return {
        since: 'NHY',
        changeTimeRange(time) {
            set(state => {
                state.since = time;
            });
        },
    };
});
