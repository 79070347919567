import { useCallback, useState } from 'react';
import { createContainer } from '~/modules/unstated-next-utils/createContainer';
import { PROPORTION_COLORS } from '~/modules/tradeSheet/shared/proportionColors';
import { useTimeRangeStore } from '~/modules/tradeSheet/shared/useTimeRangeStore';
import { useAccountPerformance } from './useAccountPerformance';
const useTradingSummaryHash = () => {
    const [hash, setHash] = useState('');
    const since = useTimeRangeStore(state => state.since);
    const handleHash = useCallback((returnValue) => {
        setHash(returnValue);
    }, []);
    const accountPerformance = useAccountPerformance(encodeURI(hash), since);
    const radarData = [
        {
            label: '勝率(%)',
            header: '勝率（獲利交易次數 / 總交易次數）',
            value: (accountPerformance?.winRate ?? 0) * 100,
            adjustValue: accountPerformance?.winRate ?? 0,
            isPercentage: true,
        },
        {
            label: '每口盈虧比',
            header: '每口盈虧比(平均每口平倉獲利/平均每口平倉虧損)',
            value: accountPerformance?.avgPnlRatio ?? 0,
            adjustValue: (accountPerformance?.avgPnlRatio ?? 0) / 2,
        },
        {
            label: '獲利因子',
            header: '獲利因子(平倉獲利累計金額/平倉虧損累計金額)',
            value: accountPerformance?.profitFactor ?? 0,
            adjustValue: (accountPerformance?.profitFactor ?? 0) / 2,
        },
        /*{
          label: '最大回撤金額',
          header: '最大回撤金額(期間權益淨值損益率拉回之最大金額)',
          value: maxDrawdown?.maxDrawdown ?? 0,
          adjustValue: (maxDrawdown?.maxDrawdown ?? 0) / 2000,
          isPercentage: false,
        },*/
    ];
    const proportionPeiChartData = accountPerformance?.instrumentStatistics
        .map((instrument, index) => ({
        name: instrument.symbol,
        value: instrument.proportion,
        color: PROPORTION_COLORS[index],
    }))
        .sort((a, b) => b.value - a.value)
        .slice(0, 10);
    return {
        state: {
            /** randomly pick two data */
            isDataReady: accountPerformance?.winRate && accountPerformance.dailyPnl ? true : false,
            netProfit: accountPerformance?.netProfit,
            maxDrawdown: accountPerformance?.maxDrawdown,
            dailyPnl: accountPerformance?.dailyPnl,
            avgTradesPerDay: accountPerformance?.avgTradesPerDay,
            avgLotsPerDay: accountPerformance?.avgLotsPerDay,
            radarData: radarData,
            top3Winners: accountPerformance?.top3Winners,
            top3Losers: accountPerformance?.top3Losers,
            proportionPeiChartData,
        },
        acts: {
            handleHash,
        },
    };
};
export const useTradingSummaryHashState = createContainer(useTradingSummaryHash);
