import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { memo } from 'react';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { store } from '~/pages/heineken_template/_private/store';
import { david_store } from '../david_store';
const priceBackgroundLong = 'background: linear-gradient(90deg, #666666aa, #666666aa);';
const priceBackgroundShort = 'background: linear-gradient(90deg, #666666aa, #666666aa);';
const priceBackgroundSwing = 'background: linear-gradient(90deg, #666666aa, #666666aa);';
const getDecimalPlaces = (valur) => {
    if (Number.isInteger(valur)) {
        return 0; // 整數，沒有小數位
    }
    const decimalPart = String(valur).split('.')[1]; // 將數字轉換為字串，並以小數點分割取得小數部分
    return decimalPart?.length; // 回傳小數部分的長度即為小數位數
};
export const RangeZone = memo(function RangeZone() {
    const charting = useSnapshot(store.charting);
    const symbol = charting.symbol;
    const fixed = symbol === 'NG-1' ? 3 : symbol === 'CL-1' ? 2 : 0;
    //huanan_DailyRange 指標傳參 (夜盤高、夜盤低、收盤)
    const state = useSnapshot(david_store);
    const dayLow = state.rangeLow;
    const dayHigh = state.rangeHigh;
    const close = state.close;
    const fix = getDecimalPlaces(close);
    /** 夜盤高區間 */
    const pmHighZone = [dayHigh * 1.001, dayHigh * 0.999];
    /** 夜盤低區間 */
    const pmLowZone = [dayLow * 1.001, dayLow * 0.999];
    /** 夜盤高低中間價 */
    const dayMid = (dayHigh + dayLow) / 2;
    /** 最遠區間上下距離 */
    const far = (dayHigh - dayLow) * 1;
    const maxHigh = dayMid + far;
    const minLow = dayMid - far;
    const top = maxHigh * 1.012;
    const bottom = minLow * 0.9875;
    /** 圖表最大區間 */
    const allDiff = maxHigh - minLow;
    const range = top - bottom;
    const newDiff = pmHighZone[0] - pmLowZone[1];
    //rows的高度 根據真實價格分配出來
    /** H區 */
    const zoneH = ((maxHigh - pmHighZone[0]) / newDiff) * 100;
    /** 拉回支撐區(E) */
    const zoneE = ((pmHighZone[0] - dayHigh) / newDiff) * 100;
    /** 反彈壓力區(C) */
    const zoneC = ((dayHigh - pmHighZone[1]) / newDiff) * 100;
    /** 盤整區(A) */
    const zoneN = ((pmHighZone[1] - pmLowZone[0]) / newDiff) * 100;
    /** 拉回支撐區(D) */
    const zoneD = ((pmLowZone[0] - dayLow) / newDiff) * 100;
    /** 反彈壓力區(F) */
    const zoneF = ((dayLow - pmLowZone[1]) / newDiff) * 100;
    /** G區 */
    const zoneG = ((pmLowZone[1] - minLow) / newDiff) * 100;
    /** 在數值尚未回傳 將保持元件高度為預設 */
    const releaseHeight = state.close === 0 ? 0 : 6.4;
    //控制price bar
    const nowPriceHight = ((pmHighZone[0] - close) / newDiff) * 100;
    const triangleHight = 100 - nowPriceHight;
    //小於100%正常 => value , < 0大漲過高 => 0
    const priceTemplaterows1 = nowPriceHight < 100 && nowPriceHight >= 0 ? nowPriceHight : nowPriceHight < 0 ? 0 : 100;
    const priceTemplaterows2 = nowPriceHight < 100 && nowPriceHight >= 0 ? triangleHight : 0;
    //在超漲區時使用margin為箭頭;若在空白區則顯示在線上
    const limitLocaltion = close > Number(top.toFixed(fix)) ? -20 : close < Number(bottom.toFixed(fix)) ? 20 : 0;
    //現價是否在某區間內
    const includeRange = (min, max) => {
        if (Number(close.toFixed(fix)) >= Number(min.toFixed(fix)) &&
            Number(close.toFixed(fix)) < Number(max.toFixed(fix))) {
            return true;
        }
        else
            return false;
    };
    const Area = memo(function Area() {
        return (<div css={css `
          ${fill_vertical_all_center};
        `}>
        {/** 頂部放空 */}
        <styleds.borderPrice>
          <styleds.rangeArea fill='#33bb33'>超漲壓力區</styleds.rangeArea>
          <styleds.priceAreaShort priceInRange={includeRange(top, 1e9)}>
            {top.toFixed(fixed)}以上
          </styleds.priceAreaShort>
        </styleds.borderPrice>
        <styleds.zoneContainer>
          {/** 區域名稱 */}
          <styleds.rangeAreaContainer releaseHeight={releaseHeight} css={css `
              grid-template-rows: ${zoneE.toFixed(1)}% ${zoneC.toFixed(1)}% ${zoneN.toFixed(1)}% ${zoneD.toFixed(1)}% ${zoneF.toFixed(1)}%;
            `}>
            {/* <styleds.rangeArea fill='#666666'></styleds.rangeArea> */}
            <styleds.rangeArea fill='#e43f3f'>拉回支撐區(E)</styleds.rangeArea>
            <styleds.rangeArea fill='#33bb33'>反彈壓力區(C)</styleds.rangeArea>
            <styleds.rangeArea fill='#666666'>盤整區(A)</styleds.rangeArea>
            <styleds.rangeArea fill='#e43f3f'>拉回支撐區(D)</styleds.rangeArea>
            <styleds.rangeArea fill='#33bb33'>反彈壓力區(F)</styleds.rangeArea>
            {/* <styleds.rangeArea fill='#666666'></styleds.rangeArea> */}
          </styleds.rangeAreaContainer>

          {/** 價格 */}
          <styleds.priceAreaContainter releaseHeight={releaseHeight} css={css `
              grid-template-rows: ${zoneE.toFixed(1)}% ${zoneC.toFixed(1)}% ${zoneN.toFixed(1)}% ${zoneD.toFixed(1)}% ${zoneF.toFixed(1)}%;
            `}>
            <styleds.priceAreaLong priceInRange={includeRange(dayHigh, pmHighZone[0])}>
              {dayHigh.toFixed(fixed)}~{pmHighZone[0].toFixed(fixed)}
            </styleds.priceAreaLong>
            <styleds.priceAreaShort priceInRange={includeRange(pmHighZone[1], dayHigh)}>
              {pmHighZone[1].toFixed(fixed)}~{dayHigh.toFixed(fixed)}
            </styleds.priceAreaShort>
            <styleds.priceAreaSwing priceInRange={includeRange(pmLowZone[0], pmHighZone[1])}>
              {dayMid.toFixed(fixed)}
            </styleds.priceAreaSwing>
            <styleds.priceAreaLong priceInRange={includeRange(dayLow, pmLowZone[0])}>
              {dayLow.toFixed(fixed)}~{pmLowZone[0].toFixed(fixed)}
            </styleds.priceAreaLong>
            <styleds.priceAreaShort priceInRange={includeRange(pmLowZone[1], dayLow)}>
              {pmLowZone[1].toFixed(fixed)}~{dayLow.toFixed(fixed)}
            </styleds.priceAreaShort>
          </styleds.priceAreaContainter>
          {/** priceBar */}
          <styleds.priceBar css={css `
              grid-template-rows: ${priceTemplaterows1}% ${priceTemplaterows2}%;
            `}>
            <div></div>
            <styleds.priceBarContent css={css `
                margin-top: ${limitLocaltion}%;
                z-index: 999;
              `}>
              <styleds.triangle />
              <span>{close}</span>
            </styleds.priceBarContent>
          </styleds.priceBar>
        </styleds.zoneContainer>
        {/** 底部作多 */}
        <styleds.borderPrice>
          <styleds.rangeArea fill='#e43f3f'>超跌支撐區</styleds.rangeArea>
          <styleds.priceAreaLong priceInRange={includeRange(0, bottom)}>
            {bottom.toFixed(fixed)}以下
          </styleds.priceAreaLong>
        </styleds.borderPrice>
      </div>);
    });
    return (<div css={css `
        ${fill_horizontal_all_center};
        min-height: 320px;
      `}>
      <Area />
    </div>);
});
const styleBase = {
    priceArea: `
  flex-direction: row-reverse;
  align-items: center;
  `,
};
const styleds = {
    zoneContainer: styled.div `
    display: grid;
    grid-template-columns: 40% 40% 20%;
    height: calc(100% - 48px);
    width: 100%;
    padding: 2px;
    & > div {
      width: 100%;
      font-size: 14px;
      line-height: 14px;
    }
  `,
    rangeAreaContainer: styled.div `
    display: grid;
    height: calc(100% - ${props => props.releaseHeight}px);
    row-gap: 1.6px;
    & > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fefefe;
      border-radius: 4px 0 0 4px;
    }
  `,
    rangeArea: styled.div `
    background-color: ${props => props.fill};
    border-radius: 4px 0px 0px 4px;
  `,
    priceAreaContainter: styled.div `
    display: grid;
    width: 100%;
    height: calc(100% - ${props => props.releaseHeight}px);
    row-gap: 1.6px;
  `,
    priceAreaLong: styled.div `
    ${fill_vertical_all_center};
    ${styleBase.priceArea}
    ${priceBackgroundLong}
    color: #ff3f0f;
    border: 1px solid ${props => (props.priceInRange === true ? '#ffffff' : '#ffffff00')};
  `,
    priceAreaShort: styled.div `
    ${fill_vertical_all_center};
    ${styleBase.priceArea}
    ${priceBackgroundShort};
    color: #3fea3f;
    border: 1px solid ${props => (props.priceInRange === true ? '#ffffff' : '#ffffff00')};
  `,
    priceAreaSwing: styled.div `
    ${fill_vertical_all_center};
    flex-direction: row-reverse;
    align-items: center;
    ${priceBackgroundSwing};
    border: 1px solid ${props => (props.priceInRange === true ? '#ffffff' : '#ffffff00')};
  `,
    priceBar: styled.div `
    display: grid;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #ff4c4c, #f46666aa, #aaaaaaaa, #49cb50aa, #49cb50);
    border-radius: 0px 4px 4px 0px;
  `,
    priceBarContent: styled.div `
    display: flex;
    flex-direction: row;
    > span {
      font-size: 12px;
      margin-top: -6px;
    }
  `,
    triangle: styled.div `
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 12px solid #fffb00cc;
    margin-top: -8px;
    margin-left: -4px;
  `,
    borderPrice: styled.div `
    ${fill_horizontal_all_center};
    display: grid;
    grid-template-columns: 40% 60%;
    height: 24px;
    padding: 0px 2px;
    & > div {
      ${fill_horizontal_all_center};
      width: 100%;
      font-size: 14px;
      line-height: 14px;
    }
  `,
};
//box-shadow: inset 0 1px 0 ${borderFill};
