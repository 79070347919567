export const AMP_DEFAULT_ARRAY = [
    {
        Time: '09:00',
        Progress: 0,
        AvgCurrentAmp: NaN,
        CurrentAmp: NaN,
        CumulativeAmp: NaN,
        AvgCumulativeAmp: NaN,
    },
    {
        Progress: 0,
        AvgCumulativeAmp: NaN,
        CurrentAmp: NaN,
        AvgCurrentAmp: NaN,
        CumulativeAmp: NaN,
        Time: '09:15',
    },
    {
        CumulativeAmp: NaN,
        AvgCumulativeAmp: NaN,
        CurrentAmp: NaN,
        Time: '09:30',
        Progress: 0,
        AvgCurrentAmp: NaN,
    },
    {
        AvgCumulativeAmp: NaN,
        CumulativeAmp: NaN,
        AvgCurrentAmp: NaN,
        Progress: 0,
        Time: '09:45',
        CurrentAmp: NaN,
    },
    {
        Time: '10:00',
        Progress: 0,
        AvgCumulativeAmp: NaN,
        AvgCurrentAmp: NaN,
        CurrentAmp: NaN,
        CumulativeAmp: NaN,
    },
    {
        Progress: 0,
        CurrentAmp: NaN,
        AvgCumulativeAmp: NaN,
        CumulativeAmp: NaN,
        Time: '10:15',
        AvgCurrentAmp: NaN,
    },
    {
        AvgCurrentAmp: NaN,
        CurrentAmp: NaN,
        Time: '10:30',
        Progress: 0,
        AvgCumulativeAmp: NaN,
        CumulativeAmp: NaN,
    },
    {
        AvgCurrentAmp: NaN,
        Progress: 0,
        CumulativeAmp: NaN,
        Time: '11:00',
        AvgCumulativeAmp: NaN,
        CurrentAmp: NaN,
    },
    {
        CurrentAmp: NaN,
        AvgCumulativeAmp: NaN,
        AvgCurrentAmp: NaN,
        Progress: 0,
        CumulativeAmp: NaN,
        Time: '12:00',
    },
    {
        Time: '13:45',
        AvgCurrentAmp: NaN,
        AvgCumulativeAmp: NaN,
        CurrentAmp: NaN,
        Progress: 0,
        CumulativeAmp: NaN,
    },
];
export const VOLUME_DEFAULT_ARRAY = [
    {
        Time: '09:00',
        Progress: 0,
        AvgCurrentVol: NaN,
        CurrentVol: NaN,
        CumulativeVol: NaN,
        AvgCumulativeVol: NaN,
    },
    {
        Progress: 0,
        AvgCumulativeVol: NaN,
        CurrentVol: NaN,
        AvgCurrentVol: NaN,
        CumulativeVol: NaN,
        Time: '09:15',
    },
    {
        CumulativeVol: NaN,
        AvgCumulativeVol: NaN,
        CurrentVol: NaN,
        Time: '09:30',
        Progress: 0,
        AvgCurrentVol: NaN,
    },
    {
        AvgCumulativeVol: NaN,
        CumulativeVol: NaN,
        AvgCurrentVol: NaN,
        Progress: 0,
        Time: '09:45',
        CurrentVol: NaN,
    },
    {
        Time: '10:00',
        Progress: 0,
        AvgCumulativeVol: NaN,
        AvgCurrentVol: NaN,
        CurrentVol: NaN,
        CumulativeVol: NaN,
    },
    {
        Progress: 0,
        CurrentVol: NaN,
        AvgCumulativeVol: NaN,
        CumulativeVol: NaN,
        Time: '10:15',
        AvgCurrentVol: NaN,
    },
    {
        AvgCurrentVol: NaN,
        CurrentVol: NaN,
        Time: '10:30',
        Progress: 0,
        AvgCumulativeVol: NaN,
        CumulativeVol: NaN,
    },
    {
        AvgCurrentVol: NaN,
        Progress: 0,
        CumulativeVol: NaN,
        Time: '11:00',
        AvgCumulativeVol: NaN,
        CurrentVol: NaN,
    },
    {
        CurrentVol: NaN,
        AvgCumulativeVol: NaN,
        AvgCurrentVol: NaN,
        Progress: 0,
        CumulativeVol: NaN,
        Time: '12:00',
    },
    {
        Time: '13:45',
        AvgCurrentVol: NaN,
        AvgCumulativeVol: NaN,
        CurrentVol: NaN,
        Progress: 0,
        CumulativeVol: NaN,
    },
];
