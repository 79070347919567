import React, { memo } from 'react';
import { useTradingSummaryHashState } from '~/modules/tradeSheet/tradingAnalyzer/useTradingSummaryHashState';
import { top3KeyValueCss, top3KeyCss, top3ValueCss, } from '~/modules/tradeSheet/tradingAnalyzer/tradingAnalyzerCss';
const Top3Winners = memo(function Top3Winners(props) {
    const { state: { top3Winners }, } = useTradingSummaryHashState.useContainer();
    return (<>
      {top3Winners.map(winner => {
            return winner.symbol === '' ? (<Empty />) : (<div css={top3KeyValueCss}>
            {' '}
            <p css={top3KeyCss}>{winner.symbol}</p>
            <p css={top3ValueCss}>$ {USPriceFormat(winner.netPnl)}</p>
          </div>);
        })}
    </>);
});
const Top3Losers = memo(function Top3Losers() {
    const { state: { top3Losers }, } = useTradingSummaryHashState.useContainer();
    return (<>
      {top3Losers.map(loser => {
            return loser.symbol === '' ? (<Empty />) : (<div css={top3KeyValueCss}>
            {' '}
            <p css={top3KeyCss}>{loser.symbol}</p>
            <p css={top3ValueCss}>$ {USPriceFormat(loser.netPnl)}</p>
          </div>);
        })}
    </>);
});
const Empty = memo(function Empty() {
    return <div css={top3KeyValueCss}>-</div>;
});
const USPriceFormat = (n) => {
    const isNegative = n < 0;
    const strNum = Math.abs(n).toString();
    const numberNFloat = strNum.split('.');
    const reversedN = numberNFloat[0].split('').reverse();
    const reverseReversedN = reversedN
        .map((charNum, index) => index !== reversedN.length - 1 && (index + 1) % 3 === 0 ? `,${charNum}` : charNum)
        .reverse()
        .join('');
    return `${isNegative ? '-' : ''}${reverseReversedN}${numberNFloat[1] ? /*`.${numberNFloat[1]}`*/ '' : ''}`;
};
export default { Winners: Top3Winners, Losers: Top3Losers };
